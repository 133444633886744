import { media, selectTheme, styled } from '@creditas-ui/react'

export const Container = styled.section`
  background: ${selectTheme('colors.neutral.90')};
  width: 100%;
`

export const BenefitsContainer = styled.div`
  padding: 96px 20px 140px;
  margin: 0 auto;
  max-width: 1366px;

  ${media.up('5xl')} {
    padding: 96px 0 159px;
  }

  ${media.between('5xl', '8xl')} {
    padding-left: 71px;
  }
`

export const TitleBenefitsContainer = styled.div`
  margin: 0 0 48px 0;
  max-width: 500px;

  ${media.up('5xl')} {
    margin: 0 0 52px 0;
  }
`

export const ContainerListCards = styled.div`
  ${media.up('8xl')} {
    margin-right: 0;
    width: calc(100% + 71px);
  }

  ${media.up('8xl')} {
    width: calc(1358px + ((100vw - 1366px) / 2));
  }

  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  padding-bottom: 40px;

  ::-webkit-scrollbar {
    height: 8px;
    border-radius: 20px;
    background: ${selectTheme('colors.neutral.10')};
    box-shadow: inset 0 -2px 0 0 ${selectTheme('colors.neutral.transparent')},
      inset 0 2px 0 0 ${selectTheme('colors.neutral.transparent')};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${selectTheme('colors.primary.40')};
    border-radius: 20px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  .scroller {
    overflow-x: scroll;
    scrollbar-color: ${selectTheme('colors.primary.40')}
      ${selectTheme('colors.neutral.10')};
    scrollbar-width: thin;
  }
`

export const CardContainer = styled.div`
  max-width: 295px;
  min-width: 290px;
  height: 457px;
  background: ${selectTheme('colors.neutral.0')};
  box-shadow: ${selectTheme('shadow.lg')};
  border-radius: 24px;
  margin-right: 28px;
  cursor: pointer;

  :last-of-type {
    margin-right: 10px;
  }

  ${media.up('5xl')} {
    min-width: 374px;
    height: 482px;
  }
`

export const ImageContainer = styled.div`
  > div {
    width: 100%;
    height: 200px;

    img {
      border-radius: 24px 24px 0 0;
      width: 100%;
      height: 100%;
    }

    ${media.up('5xl')} {
      height: 235px;
    }
  }
`

export const BodyCardContainer = styled.div`
  padding: 16px;
  display: grid;
  grid-template-rows: 68px 96px 60px;
  box-sizing: border-box;

  > p {
    height: 100%;

    :last-of-type {
      border-bottom: 1px solid ${selectTheme('colors.neutral.10')};
    }
  }

  > div {
    align-items: center;
    align-self: flex-end;
  }

  ${media.up('5xl')} {
    padding: 32px;
    grid-template-rows: 42px 72px 68px;
  }
`

export const RequestLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
`
