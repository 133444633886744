import Asset from 'utils/assets'

const assets = new Asset('landing/common/atWorkSolutions')

const imageDesktop1 = assets.v1('img-03-desktop.webp')
const imageDesktop2 = assets.v1('img-04-desktop.webp')
const imageDesktop3 = assets.v1('img-05-desktop.webp')
const imageMobile1 = assets.v1('img-03-mobile.webp')
const imageMobile2 = assets.v1('img-04-mobile.webp')
const imageMobile3 = assets.v1('img-05-mobile.webp')

export const imagesDesktop = [imageDesktop1, imageDesktop2, imageDesktop3]
export const imagesMobile = [imageMobile1, imageMobile2, imageMobile3]
