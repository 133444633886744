import {
  styled,
  media,
  selectTheme,
  Typography,
  ButtonPrimaryArrowOnly,
  Flex,
} from '@creditas-ui/react'

export const Wrapper = styled.section`
  background: ${selectTheme('colors.neutral.10')};
  width: 100%;
  position: relative;

  :before {
    content: '';
    background: ${selectTheme('colors.neutral.90')};
    width: 100vw;
    height: 250px;
    position: absolute;
    bottom: -1px;
    left: 0;
  }
`

export const Container = styled.div`
  padding: 72px 20px 46px;
  margin: 0 auto;
  max-width: 1366px;
  position: relative;

  ${media.up('5xl')} {
    padding: 96px 0 32px;
  }
  ${media.between('5xl', '8xl')} {
    padding-left: 71px;
  }
`

export const Title = styled(Typography)`
  margin-bottom: 16px;
  max-width: 750px;

  ${media.up('5xl')} {
    margin-bottom: 24px;
  }
`

export const Description = styled.p`
  ${media.down('4xl')} {
    display: inline;
  }
`

export const ReferralLink = styled.a`
  display: block;
  color: ${selectTheme('colors.primary.80')};
  text-decoration: none;
  :hover,
  :focus {
    text-decoration: underline;
  }
  ${media.up('2xl')} {
    display: inline;
  }
`

export const CardsContainer = styled(Flex)`
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 48px;
  padding-bottom: 40px;
  width: calc(100% + 20px);
  ${media.up('5xl')} {
    width: 100%;
  }
  ::-webkit-scrollbar {
    height: 8px;
    border-radius: ${selectTheme('radius.6xl')};
    background: ${selectTheme('colors.neutral.10')};
    box-shadow: inset 0 -2px 0 0 ${selectTheme('colors.neutral.transparent')},
      inset 0 2px 0 0 ${selectTheme('colors.neutral.transparent')};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${selectTheme('colors.primary.40')};
    border-radius: ${selectTheme('radius.6xl')};
  }
  ::-webkit-scrollbar-track {
    border-radius: ${selectTheme('radius.6xl')};
  }
  .scroller {
    overflow-x: scroll;
    scrollbar-color: ${selectTheme('colors.primary.40')}
      ${selectTheme('colors.neutral.10')};
    scrollbar-width: thin;
  }
`

export const Card = styled.div`
  min-width: 290px;
  background: ${selectTheme('colors.neutral.0')};
  margin-right: 28px;
  cursor: pointer;
  border-radius: ${selectTheme('radius.6xl')};
  overflow: hidden;
  :last-of-type {
    margin-right: 10px;
    ${media.up('8xl')} {
      margin-right: 0;
    }
  }
  :hover {
    box-shadow: ${selectTheme('shadow.lg')};
  }
  ${media.up('5xl')} {
    min-width: 392px;
  }
`

export const CardImageContainer = styled.div`
  width: 100%;
  height: 235px;
  overflow: hidden;
`

export const CardImage = styled.picture`
  source,
  img {
    width: 100%;
    height: auto;
  }
`

export const CardBody = styled(Flex)`
  padding: 32px;
  justify-content: space-between;
  height: 300px;
  ${media.up('5xl')} {
    height: 250px;
  }
`

export const CardTitle = styled(Typography)`
  margin-bottom: 16px;
`

export const ButtonContainer = styled(Flex)`
  height: 68px;
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid ${selectTheme('colors.neutral.10')};
`

export const Button = styled(ButtonPrimaryArrowOnly)`
  width: fit-content;
`
