import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonPrimaryArrowOnly, Typography } from '@creditas-ui/react'
import { LazyImage } from 'components/LazyImage'
import { track } from 'utils/tracking'
import { formatQueryParams } from 'utils/formatQueryParams'
import { navigate } from 'gatsby'
import { useLocalFlag } from 'utils/featureFlags'
import {
  BenefitsContainer,
  BodyCardContainer,
  CardContainer,
  Container,
  ContainerListCards,
  ImageContainer,
  RequestLinkContainer,
  TitleBenefitsContainer,
} from './solutions.styles'
import { imageSolutions } from './solutions.utils'

const Solutions = () => {
  const isBrowser = () => typeof window !== 'undefined'
  const pathNameUrl = isBrowser() && window.location.pathname
  const { t } = useTranslation()
  const options = useLocalFlag('solutionsFlags')
  const listCards = t('commons:solutions:cards', { returnObjects: true })

  const allowedCards = listCards
    ?.filter(item => options?.showCards.includes(item.id))
    .map((card, index) => ({ ...card, image: imageSolutions[index] }))
    .filter(item => item.linkButtton !== pathNameUrl)

  const onTrack = type => {
    const trackActionTypes = {
      home: 'GoToHomeEqPage',
      auto: 'GoToAutoPage',
      payroll: 'GoToPayrollPage',
    }
    track({
      event: 'gaMainMX',
      category: 'landingpage',
      action: trackActionTypes[type],
    })
  }

  return (
    <Container>
      <BenefitsContainer>
        <TitleBenefitsContainer>
          <Typography
            component="h2"
            variant="headingLgLight"
            color="neutral.0"
            dangerouslySetInnerHTML={{ __html: t('commons:solutions:title') }}
          />
        </TitleBenefitsContainer>
        <ContainerListCards>
          {allowedCards?.map(item => {
            return (
              <CardContainer
                key={item.title}
                onClick={() => {
                  navigate(formatQueryParams(item.linkButtton))
                }}
              >
                <ImageContainer>
                  <LazyImage src={item.image} alt={item.imageAlt} />
                </ImageContainer>
                <BodyCardContainer>
                  <Typography
                    component="h3"
                    color="neutral.90"
                    variant="bodyLgBold"
                  >
                    {item.title}
                  </Typography>
                  <Typography color="neutral.60" variant="bodyMdRegular">
                    {item.subtitle}
                  </Typography>
                  <RequestLinkContainer>
                    <Typography color="primary.90" variant="bodyMdMedium">
                      {item.requestLinkText}
                    </Typography>
                    <ButtonPrimaryArrowOnly
                      onClick={() => {
                        onTrack(item.type)
                        navigate(formatQueryParams(item.linkButtton))
                      }}
                      size="medium"
                      aria-label={item.requestLinkText}
                    />
                  </RequestLinkContainer>
                </BodyCardContainer>
              </CardContainer>
            )
          })}
        </ContainerListCards>
      </BenefitsContainer>
    </Container>
  )
}

export default Solutions
