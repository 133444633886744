import Asset from 'utils/assets'

const solutionsAssets = new Asset('landing/common/solutions')

const Image1 = solutionsAssets.v1(
  'solucion-prestamo-con-garantia-hipotecaria-creditas.webp',
)
const Image2 = solutionsAssets.v1('solucion-prestamo-con-garantia-de-auto-creditas.webp')
const Image3 = solutionsAssets.v1('solucion-prestamo-de-nomina-creditas.webp')
const Image4 = solutionsAssets.v1('solucion-credito-automotriz-creditas.webp')
const Image5 = solutionsAssets.v1('solucion-beneficios-corporativos-creditas.webp')

export const imageSolutions = [Image1, Image2, Image3, Image4, Image5]
