/**
 * @deprecated Use `useAsset` hook instead.
 */
class Asset {
  constructor(prefix) {
    this.prefix = prefix
  }

  get(fullPath) {
    return `${process.env.MEXICO_ASSETS_URL}/${fullPath}`
  }

  v1(file) {
    return this.prefix
      ? `${process.env.MEXICO_ASSETS_URL}/1.0.0/${this.prefix}/${file}`
      : `${process.env.MEXICO_ASSETS_URL}/1.0.0/${file}`
  }
}

export default Asset
